<template>
  <div>
    <PageHeader />
    <slot />
    <PageFooter />
    <Transition name="popup">
      <Auth v-if="userStore.authPopupIsRequired" />
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import Auth from "~/components/Auth.vue";
const userStore = useUserStore();
</script>
