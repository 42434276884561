<template>
  <SimpleDropdown
    :show-values="showUserMenu"
    :values-classes="[$style['user-menu']]"
    @outside-click="showUserMenu = false"
  >
    <template #caller>
      <SecondaryButton
        :class="$style['user-menu-btn']"
        data-test="user-menu-btn"
        @click="showUserMenu = true"
      >
        <Avatar
          size="s"
          :src="userStore.userData?.avatar_img || defaultAvatar"
        />
        <IconLeftArrow
          :class="[$style.arrow, showUserMenu ? $style.inverted : '']"
        />
      </SecondaryButton>
    </template>
    <template #values>
      <div :class="$style['inner-container']">
        <SecondaryButton
          :class="$style.btn"
          data-test="dropdown-menu-profile-btn"
          @click="handleProfileBtnClick"
        >
          <span :class="$style['icon-container']"><IconUser /></span>
          {{ t("user_menu.profile") }}
        </SecondaryButton>
      </div>
      <div :class="$style['inner-container']">
        <SecondaryButton
          :class="$style.btn"
          data-test="dropdown-menu-logout-btn"
          @click="userStore.logout"
        >
          <span :class="$style['icon-container']"><IconLogout /></span>
          {{ t("user_menu.logout") }}
        </SecondaryButton>
      </div>
    </template>
  </SimpleDropdown>
</template>

<script setup lang="ts">
import SimpleDropdown from "~/components/Shared/Dropdown/SimpleDropdown.vue";
import SecondaryButton from "~/components/Shared/Button/SecondaryButton.vue";
import Avatar from "~/components/Shared/Avatar/index.vue";
// @ts-ignore
import defaultAvatar from "~/assets/default-avatar.png";

const { t } = useI18n();
const userStore = useUserStore();
const showUserMenu = ref(false);

function handleProfileBtnClick() {
  showUserMenu.value = false;
  navigateTo("/profile");
}
</script>

<style lang="scss" module>
.user-menu {
  & > div {
    padding: 0 !important;
  }
}
.user-menu-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 78px;
  padding: var(--spacing-2xs);
  padding-right: var(--spacing-2lg);
}
.arrow {
  flex-shrink: 0;
  transform: rotate(-90deg);
}
.inverted {
  transform: rotate(90deg);
}
.inner-container {
  padding: var(--spacing-md);
  &:last-of-type {
    border-top: 1px solid var(--button-secondary-hover);
  }
}
.btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 56px;
  border-radius: var(--radius-2xl);
  text-align: left;
  background: none !important;
  &:hover {
    background: var(--cells-hover) !important;
  }
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: var(--spacing-md);
  border-radius: var(--radius-xs);
  background-color: var(--general-icon-substrate);
  svg {
    width: 16px;
  }
}
</style>
